import { useState } from "react";

import { ConsultantsDTO } from "../../../dtos/consultants";
import { data as dataConsultants } from "../../../data/mockConsultants";

export function useListConsultants() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConsultantsDTO[]>([]);

  const getConsultants = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataConsultants);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getConsultants, data, setData };
}
