
import { DependentsDTO } from "../dtos/dependents";


export let data: DependentsDTO[] = [
  {
    nomeDependente: "Seminar Neves dos Santos",
    sexo: "M",
    dataNascimento: "11-05-1990",
    nomeMae: "Fatima Batista",
    plano: "Integral DOC LE", 
    dataInclusao: "11-05-2023",
    grauParentesco: "seminar@gmail.com",
    edit: true,
  },
  {
    nomeDependente: "Gustavo Neves dos Santos",
    sexo: "M",
    dataNascimento: "19-05-1990",
    nomeMae: "Fatima Batista",
    plano: "Integral DOC LE", 
    dataInclusao: "11-05-2023",
    grauParentesco: "gu@gmail.com",
    edit: true,
  },
  {
    nomeDependente: "Carla Neves dos Santos",
    sexo: "M",
    dataNascimento: "10-05-1990",
    nomeMae: "Fatima Batista",
    plano: "Integral DOC LE", 
    dataInclusao: "11-05-2023",
    grauParentesco: "carla@gmail.com",
    edit: true,
  },
  
];
