import { ConsultantsDTO } from "../dtos/consultants";

export let data: ConsultantsDTO[] = [
  {
    id: 1,
    nome: "Seminar Neves dos Santos",
    rg: "26.561.228-7",
    cpf: "031.418.790-10",
    vinculo: "Externo",
    celular: "(73) 9 9815-2038",
    email: "seminar@gmail.com",
    action: true,
  },
  {
    id: 2,
    nome: "Seminar Neves Ribeiro",
    rg: "26.561.228-7",
    cpf: "031.418.790-10",
    vinculo: "Externo",
    celular: "(73) 9 9815-2038",
    email: "seminar@gmail.com",
    action: true,
  },
  {
    id: 3,
    nome: "Seminar Neves do Nascimento",
    rg: "26.561.228-7",
    cpf: "031.418.790-10",
    vinculo: "Externo",
    celular: "(73) 9 9815-2038",
    email: "seminar@gmail.com",
    action: true,
  },
  {
    id: 4,
    nome: "Seminar Neves do Nascimento",
    rg: "26.561.228-7",
    cpf: "031.418.790-10",
    vinculo: "Externo",
    celular: "(73) 9 9815-2038",
    email: "seminar@gmail.com",
    action: true,
  },
];
