import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes";
import { themeChakra } from "./styles/theme-chakra";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <ChakraProvider theme={themeChakra}>
      <AuthProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
