import { Navigate, Route, Routes } from "react-router-dom";
import { AuthRoute } from "./auth-route";

import { Login } from "../pages/auth/Login";
import { RecoverPassword } from "../pages/auth/RecoverPassword";
import { UpdatePassword } from "../pages/auth/UpdatePassword";
import { Home } from "../pages/home/Home";
import { Consultants } from "../pages/consultants/Consultants";
import { ConsultantRegister } from "../pages/consultants/ConsultantRegister";

import { Plans } from "../pages/plans/Plans";
import { PlanRegister } from "../pages/plans/PlanRegister";

import { Commissions } from "../pages/commissions/Commissions";
import { Sales } from "../pages/sales/Sales";
import { SaleRegister } from "../pages/sales/saleRegister";
import { PromotionRegister } from "../pages/promotions/PromotionRegister";

import { Clients } from "../pages/clients/Clients";
import { ClientRegister } from "../pages/clients/ClientRegister";
import { ContractRegister } from "../pages/contract/ContractRegister";
import { HolderRegister } from "../pages/holder/HolderRegister";
import { DependentRegister } from "../pages/dependents/DependentRegister";
import { DisclosureRegister } from "../pages/disclosure/DisclosureRegister";
import { Dependents } from "../pages/dependents/Dependents";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route
        path="/home"
        element={
          <AuthRoute path="home">
            <Home />
          </AuthRoute>
        }
      />
      <Route
        path="/consultores"
        element={
          <AuthRoute path="consultores">
            <Consultants />
          </AuthRoute>
        }
      />
      <Route
        path="/consultores/cadastro"
        element={
          <AuthRoute path="/consultores/cadastro">
            <ConsultantRegister />
          </AuthRoute>
        }
      />

      <Route
        path="/planos"
        element={
          <AuthRoute path="/planos">
            <Plans />
          </AuthRoute>
        }
      />
      <Route
        path="/planos/cadastro"
        element={
          <AuthRoute path="/planos/cadastro">
            <PlanRegister />
          </AuthRoute>
        }
      />

      <Route
        path="/comissoes"
        element={
          <AuthRoute path="/comissoes">
            <Commissions />
          </AuthRoute>
        }
      />
      <Route
        path="/vendas"
        element={
          <AuthRoute path="/comissoes">
            <Sales />
          </AuthRoute>
        }
      />
      <Route
        path="/vendas/cadastro"
        element={
          <AuthRoute path="/vendas/cadastro">
            <SaleRegister />
          </AuthRoute>
        }
      />
      <Route
        path="/promocoes/cadastro"
        element={
          <AuthRoute path="/promocoes/cadastro">
            <PromotionRegister />
          </AuthRoute>
        }
      />

      <Route
        path="/clientes"
        element={
          <AuthRoute path="clientes">
            <Clients />
          </AuthRoute>
        }
      />
      <Route
        path="/clientes/cadastro"
        element={
          <AuthRoute path="clientes">
            <ClientRegister />
          </AuthRoute>
        }
      />
      <Route
        path="/clientes/info/cadastro"
        element={
          <AuthRoute path="clientes">
            <ContractRegister />
          </AuthRoute>
        }
      />

      <Route
        path="/titular/cadastro"
        element={
          <AuthRoute path="clientes">
            <HolderRegister />
          </AuthRoute>
        }
      />
      <Route
        path="/dependentes/cadastro"
        element={
          <AuthRoute path="clientes">
            <DependentRegister />
          </AuthRoute>
        }
      />
      <Route
        path="/divulgacao/cadastro"
        element={
          <AuthRoute path="clientes">
            <DisclosureRegister />
 </AuthRoute>
        }
      />
       <Route
        path="/dependentes"
        element={
          <AuthRoute path="clientes">
            <Dependents />
          </AuthRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperar" element={<RecoverPassword />} />
      <Route path="/alterar/:email" element={<UpdatePassword />} />
    </Routes>
  );
}
