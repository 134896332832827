import {
    Box,
    Flex,
    Img,
    Spinner,
    Text,    
  } from "@chakra-ui/react";
  import { themeChakra } from "../../styles/theme-chakra";
  import { useEffect } from "react";
  
  import { useListDependents } from "./hooks/useListDependents";

  
  type Props = {
    term: string | undefined;
  };
  
  export const DependentsTable = ({ term }: Props) => {
    const { data, getDependents, isLoading } = useListDependents();

    const dataFiltred = term
      ? data.filter((data) =>
          data.nomeDependente.toLocaleLowerCase().includes(term.toLocaleLowerCase())
        )
      : data;
  
    const columns = [
    "Nome Dependente",
    "Sexo",
    "Nascimento",
    "Nome da Mãe",
    "Plano",
    "Inclusão",
    "Parentesco",
      "%",
    ];
  
    useEffect(() => {
      getDependents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return !isLoading ? (
      <Box
        as="table"
        width="100%"
        margin="32px 0"
        style={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
      >
        <Box
          as="thead"
          boxShadow="1px 2px 19px 2px #CBCBCB"
          bgGradient="linear-gradient(to right, #FAFAFA, #E7E8E8)"
          borderRadius={44}
        >
          <Box as="tr">
            {columns.map((column, index) => (
              <Box
                key={index}
                as="th"
                padding="12px"
                textAlign="center"
                borderBottom="1px solid #CBCBCB"
                color={themeChakra.colors.blue[600]}
                borderRadius={
                  index === 0
                    ? "16px 0 0 16px"
                    : index === columns.length - 1
                    ? "0 16px 16px 0"
                    : ""
                }
              >
                {column}
              </Box>
            ))}
          </Box>
        </Box>
        <Box as="tbody">
          {dataFiltred.length > 0 ? (
            dataFiltred.map((item, rowIndex) => (
              <Box
                key={rowIndex}
                as="tr"
                borderBottom="1px solid #CBCBCB"
                padding="12px"
              >
                {Object.entries(item).map(([_, value], colIndex) => (
                  <Box
                    key={colIndex}
                    as="td"
                    fontSize={14}
                    padding="12px"
                    textAlign="center"
                    backgroundColor={themeChakra.colors.blue[600]}
                    color="white"
                    borderRadius={
                      colIndex === 0
                        ? "16px 0 0 16px"
                        : colIndex === columns.length - 1
                        ? "0 16px 16px 0"
                        : ""
                    }
                  >
                    {colIndex === columns.length - 1 ? (
                      <Flex justifyContent="center">
                        <Img cursor="pointer" src="/edit.png" alt="" />

                      </Flex>
                    ) : (
                      value
                    )}
                  </Box>
                ))}
              </Box>
            ))
          ) : (
            <Box
              minHeight="100%"
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <Text
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                Nada encontrado...
              </Text>
            </Box>
          )}
        </Box>
  
        
      </Box>
    ) : (
      <Box
        minHeight="100%"
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <Spinner
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          thickness="4px"
          speed="0.65s"
          color={themeChakra.colors.blue[600]}
          size="xl"
        />
      </Box>
    );
  };
  