import { Select as ChakraSelect } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";

type Option = {
  value: string;
  text: string;
};

type SelectProps = {
  options: Option[];
  borderRadius?: string;
  margin?: string;
  width?: string;
  borderColor?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { options, borderColor, onChange, ...rest },
  ref
) => {
  return (
    <ChakraSelect
      ref={ref}
      borderColor={borderColor || "black"}
      onChange={onChange}
      {...rest}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      ))}
    </ChakraSelect>
  );
};

export const Select = forwardRef(SelectBase);
