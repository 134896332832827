import React, { useState } from "react";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";
import { themeChakra } from "../../styles/theme-chakra";
import { Select } from "../../components/select/Select";

export interface FormData {
  operadora: string;
  tipo: string;
  publico: string;
  validade: string;
  tituloMidia: string;
}

const schema = yup.object().shape({
  operadora: yup.string().required("Campo é obrigatório"),
  tipo: yup.string().required("Campo é obrigatório"),
  publico: yup.string().required("Campo é obrigatório"),
  validade: yup.string().required("Campo é obrigatório"),
  tituloMidia: yup.string().required("Campo é  obrigatório"),
});

export const DisclosureRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [file, setFile] = useState<FileList | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    setFile(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setFile(files);
  };

  const onSubmit = (data: FormData) => {
    const formData = new FormData();

    formData.append("operadora", data.operadora);
    formData.append("tipo", data.tipo);
    formData.append("publico", data.publico);
    formData.append("validade", data.validade);
    formData.append("tituloMidia", data.tituloMidia);

    if (file) {
      formData.append("file", file[0]);
    }

    console.log(formData.get("operadora"));
    console.log(formData.get("tipo"));
    console.log(formData.get("publico"));
    console.log(formData.get("validade"));
    console.log(formData.get("tituloMidia"));
    console.log(formData.get("file"));
  };

  return (
    <form
      style={{ padding: "64px 84px 16px 84px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Text
        margin="16px 0"
        color={themeChakra.colors.blue[600]}
        fontSize="22px"
        fontFamily={themeChakra.fonts.heading}
        fontWeight={themeChakra.fontWeights.bold}
      >
        Materiais de Divulgação - Nova Mídia
      </Text>
      <Grid
        gap="16px 20px"
        templateColumns="repeat(4, 1fr)"
        templateRows="repeat(2, 1fr)"
      >
        <GridItem colSpan={1}>
          <Select
            options={[
              { text: "Operadora:", value: "" },
              { text: "example", value: "example" },
            ]}
            {...register("operadora")}
            borderRadius="8px"
            borderColor={
              errors.operadora?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.operadora?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Select
            options={[
              { text: "Tipo:", value: "" },
              { text: "example", value: "example" },
            ]}
            {...register("tipo")}
            borderRadius="8px"
            borderColor={
              errors.tipo?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.tipo?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Select
            options={[
              { text: "Público:", value: "" },
              { text: "example", value: "example" },
            ]}
            {...register("publico")}
            borderRadius="8px"
            borderColor={
              errors.publico?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.publico?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("validade")}
            borderRadius="8px"
            name="validade"
            type="date"
            borderColor={
              errors.validade?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.validade?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={4}>
          <DashboardInput
            {...register("tituloMidia")}
            borderRadius="8px"
            name="tituloMidia"
            type="text"
            placeholder="Título da mídia"
            borderColor={
              errors.tituloMidia?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.tituloMidia?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={4} mt={5} mb={10}>
          <Box
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            border="2px dashed #ccc"
            borderRadius="8px"
            padding="20px"
            textAlign="center"
          >
            <DashboardInput
              name="file"
              display="none"
              type="file"
              onChange={handleFileChange}
            />
            <p>Solte o arquivo aqui para fazer o upload.</p>
          </Box>
          <Text color={themeChakra.colors.red[200]}>
            {file ? `Arquivo selecionado: ${file[0].name}` : ""}
          </Text>
        </GridItem>
      </Grid>
      <DashboardButton type="submit" margin="0 auto">
        Enviar
      </DashboardButton>
    </form>
  );
};
