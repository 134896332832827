import { Container, List } from "@chakra-ui/react";
import { SideItem } from "./SideItem";

export type SideItemProps = {
  title: string;
  link: string;
};

export const Aside = () => {
  const links: SideItemProps[] = [
    { link: "/consultores", title: "CONSULTORES DE VENDAS" },
    { link: "/planos", title: "FAIXAS, PLANOS E COMISSÕES" },
    { link: "/vendas", title: "METAS DE VENDAS" },
    { link: "/clientes", title: "CARTEIRA DE CLIENTES" },
    { link: "/divulgacao/cadastro", title: "MATERIAIS DE DIVULGAÇÃO " },
    { link: "example/", title: "REDE CREDENCIADAS" },
    { link: "example/", title: "CONSULTORES DE VENDAS RELATÓRIO" },
  ];

  return (
    <Container
      flexDirection="column"
      display="flex"
      justifyContent="start"
      marginTop="34px"
      as="aside"
      maxWidth="280px"
      minHeight="100%"
    >
      <List as="ul">
        {links.map((link, index) => (
          <SideItem key={index} link={link.link} title={link.title} />
        ))}
      </List>
    </Container>
  );
};
