import ReactInputMask from "react-input-mask";

import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputGroup,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  mask: string;
  label?: string;
  size?: "lg" | "md" | "sm";
  error?: FieldError;
  placeholder?: string;
  borderColor?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, mask, label, size = "sm", borderColor, error, placeholder, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel mb={-1} color="gray.800" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup borderColor={borderColor || 'black'}>
        <ChakraInput
          {...rest}
          id={name}
          name={name}
          borderRadius="8px"
          height="40px"
          size={size}
          _hover={{
            bgColor: "transparent",
          }}
          ref={ref}
          as={ReactInputMask}
          mask={mask}
          maskChar={null}
          placeholder={placeholder || ""}
        />
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </InputGroup>
    </FormControl>
  );
};

export const InputLineMask = forwardRef(InputBase);
