import { FormLabel } from "@chakra-ui/react";
import { ReactNode } from "react";

import { themeChakra } from "../../styles/theme-chakra";

export type LabelProps = {
  htmlFor: string;
  children: ReactNode;
};

export const AuthLabel = ({ htmlFor, children }: LabelProps) => {
  return (
    <FormLabel
      textAlign="initial"
      marginBottom={-7}
      color={themeChakra.colors.blue[600]}
      fontSize={18}
      fontWeight={themeChakra.fontWeights.light}
      htmlFor={htmlFor}
    >
      {children}
    </FormLabel>
  );
};
