import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  Grid,
  Box,
  Img,
  useMediaQuery,
  Checkbox,
  Link,
  Button,
} from "@chakra-ui/react";
import { Title } from "../../components/title/Title";
import { themeChakra } from "../../styles/theme-chakra";
import { useToast } from "@chakra-ui/react";
import { Input } from "../../components/input/Input";
import { AuthLabel } from "../../components/label/Label";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

type Form = {
  email: string;
  password: string;
};

export const Login = () => {
  const { isAuthLoading, signIn } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const createFormSchema = yup.object({
    email: yup
      .string()
      .required("Por favor, insira seu email")
      .email("Email inválido"),
    password: yup
      .string()
      .required("Por favor, insira sua senha")
      .min(5, "Mínimo 8 caracteres"),
  });

  const { register, formState, handleSubmit } = useForm<Form>({
    mode: "onChange",
    resolver: yupResolver(createFormSchema),
  });

  const { errors } = formState;
  const [isLargerThan767] = useMediaQuery("(min-width: 767px)");

  const showToast = (
    message: string,
    status: "info" | "warning" | "success" | "error" | "loading" | undefined
  ) => {
    toast({
      title: message,
      position: "top-right",
      status: status,
      duration: 9000,
      isClosable: true,
    });
  };

  const handleSend: SubmitHandler<Form> = async (values) => {
    try {
      await signIn(values);
      showToast("Logado com sucesso.", "success");
      navigate("/");
    } catch (error) {
      showToast("Erro no login. Tente novamente.", "error");
    }
  };

  return (
    <Grid
      templateColumns={isLargerThan767 ? "1.1fr 1fr" : "1fr"}
      gap={0}
      minHeight="100vh"
    >
      {isLargerThan767 && (
        <Box position="relative">
          <Img
            src="/mulher.png"
            alt="Imagem"
            objectFit="cover"
            w="100%"
            h="100vh"
          />
        </Box>
      )}
      <Flex direction="column" justify="center" align="center">
        <Box
          position="relative"
          width={isLargerThan767 ? "70%" : "100%"}
          maxWidth={500}
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={isLargerThan767 ? 8 : 2}
        >
          <Title>LOGIN</Title>
          <Flex width="100%" flexDirection="column" as="form">
            <FormControl isInvalid={!!errors.email}>
              <Stack>
                <AuthLabel htmlFor="email">Email</AuthLabel>
                <Input autoComplete="off" type="email" {...register("email")} />
                <FormErrorMessage
                  color={themeChakra.colors.red[200]}
                  marginTop="-16px"
                >
                  {errors.email?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>
            <FormControl position="relative" isInvalid={!!errors.password}>
              <Stack>
                <AuthLabel htmlFor="password">Password</AuthLabel>
                <Input
                  autoComplete="off"
                  type="password"
                  {...register("password")}
                />
                <FormErrorMessage
                  color={themeChakra.colors.red[200]}
                  marginTop="-16px"
                >
                  {errors.password?.message}
                </FormErrorMessage>
              </Stack>
              <Flex margin="10px 0" position="absolute">
                <Checkbox required={false} margin="0 8px 0 0" />
                <Text textAlign="left" color="#959595">
                  Me mantenha conectado
                </Text>
              </Flex>
              <Link
                href="/recuperar"
                position="absolute"
                right="0"
                margin="-16px 0 4px 0"
                color={themeChakra.colors.red[200]}
              >
                Esqueceu a senha?
              </Link>
            </FormControl>
            <Button
              type="button"
              color="white"
              marginTop="40px"
              _hover={{ opacity: "80%" }}
              background={themeChakra.colors.blue[600]}
              onClick={handleSubmit(handleSend)}
              isLoading={isAuthLoading}
              loadingText="Carregando..."
            >
              Enviar
            </Button>
          </Flex>
          <Link
            href="/cadastro"
            margin="16px 0"
            color={themeChakra.colors.red[200]}
          >
            Registrar um novo membro
          </Link>
        </Box>
      </Flex>
    </Grid>
  );
};
