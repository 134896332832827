import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { Select } from "../../components/select/Select";
import { themeChakra } from "../../styles/theme-chakra";

type TableProps = {
  title: string;
};

const Table = ({ title }: TableProps) => {
  const list = ["PLANO", "INDICADOR", "META", "VENDAS", "DESVIO"];
  const listData = [
    "Integral DOC LE",
    "N° de vidas",
    "20",
    "19",
    "-5,00%",
    "Master LE",
    "N° de vidas",
    "3",
    "4",
    "-33,33%",
  ];
  return (
    <Box margin="16px 0" borderRadius="8px">
      <Box padding="4px 0" borderRadius="8px 8px 0 0" as="header">
        <Flex
          padding="8px"
          borderRadius="8px 8px 0 0"
          background={themeChakra.colors.blue[700]}
          color="white"
          justify="center"
        >
          {title}
        </Flex>
        <Grid
          marginBottom="-3px"
          gap="1px"
          templateColumns="repeat(5, 1fr)"
          templateRows="repeat(1, 1fr)"
        >
          {list.map((item) => {
            return (
              <GridItem
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg={themeChakra.colors.blue[600]}
                color="white"
                padding="8px"
              >
                <Text fontSize="12"> {item}</Text>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
      <Grid
        columnGap="1px"
        templateColumns="repeat(5, 1fr)"
        templateRows="repeat(2, 1fr)"
      >
        {listData.map((item) => {
          return (
            <GridItem
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="white"
              boxShadow="1px 1px 5px 1px #808080"
              borderRight=""
              color="black"
              padding="8px"
            >
              <Text fontSize="12"> {item}</Text>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export const Home = () => {
  return (
    <Box padding="64px 84px 16px 84px" width="100%">
      <Flex justifyContent="center">
        <Select
          borderRadius="32px"
          options={[{ text: "Example 1", value: "Example 1" }]}
        ></Select>
        <Select
          margin="0 8px"
          borderRadius="32px"
          options={[{ text: "Example 1", value: "Example 1" }]}
        ></Select>
        <Select
          borderRadius="32px"
          options={[{ text: "Example 1", value: "Example 1" }]}
        ></Select>
      </Flex>
      <Grid
        columnGap="3px"
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(1, 1fr)"
        as="section"
      >
        <Box width="100%">
          <Table title="PESSOA FÍSICA" />
          <Table title="MEI (3 a 99 vidas)" />
        </Box>
        <Box width="100%">
          <Table title="PME (30 a 99 vidas)" />
          <Table title="PME (100 a 199 vidas)" />
        </Box>
      </Grid>
    </Box>
  );
};
