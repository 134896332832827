import {
  Grid,
  GridItem,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { themeChakra } from "../../styles/theme-chakra";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputLineMask } from "../../components/input-mask/InputMask";
import { Select } from "../../components/select/Select";

export interface FormData {
  cliente: string;
  cnpj: string;
  faixa: string;
  formaContribuicao: string;
  nome: string;
  sexo: string;
  dataNascimento: string;
  cpf: string;
  nomeMae: string;
  plano: string;
  valorPlano: string;
  descontoFolha: string;
  dataInclusao: string;
  telefoneContato: string;
  numeroMatricula: string;
  observacoes: string;
}

export const HolderRegister = () => {
  const schema = yup.object().shape({
    cliente: yup.string().required("Campo obrigatório"),
    cnpj: yup.string().required("Campo obrigatório"),
    faixa: yup.string().required("Campo obrigatório"),
    formaContribuicao: yup.string().required("Campo obrigatório"),
    nome: yup.string().required("Campo obrigatório"),
    sexo: yup.string().required("Campo obrigatório"),
    dataNascimento: yup.string().required("Campo obrigatório"),
    cpf: yup.string().required("Campo obrigatório"),
    nomeMae: yup.string().required("Campo obrigatório"),
    plano: yup.string().required("Campo obrigatório"),
    valorPlano: yup.string().required("Campo obrigatório"),
    descontoFolha: yup.string().required("Campo obrigatório"),
    dataInclusao: yup.string().required("Campo obrigatório"),
    telefoneContato: yup.string().required("Campo obrigatório"),
    numeroMatricula: yup.string().required("Campo obrigatório"),
    observacoes: yup.string().required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <Tabs
      padding="64px 84px 16px 84px"
      justifyContent="center"
      position="relative"
      variant="unstyled"
    >
      <TabList>
        <Tab>Primeira Etapa</Tab>
        <Tab>Segunda Etapa</Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanels>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Cadastro de Novo Titular
            </Text>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(2, 1fr)"
              templateRows="repeat(2, 1fr)"
            >
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.cliente?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("cliente")}
                  name="cliente"
                  type="text"
                  placeholder="Cliente"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cliente?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <InputLineMask
                  borderColor={
                    errors.cnpj?.message ? themeChakra.colors.red[200] : "black"
                  }
                  mask="99.999.999/0001-99"
                  {...register("cnpj")}
                  name="cnpj"
                  type="text"
                  placeholder="CNPJ/MF"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cnpj?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Faixa:", value: "" },
                    { text: "Example", value: "example" },
                  ]}
                  borderColor={
                    errors.faixa?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("faixa")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.faixa?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Forma de Contribuição:", value: "" },
                    { text: "Example", value: "example" },
                  ]}
                  borderColor={
                    errors.formaContribuicao?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("formaContribuicao")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.formaContribuicao?.message}
                </Text>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Dados dos Titulares
            </Text>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(4, 1fr)"
              templateRows="repeat(3, 1fr)"
              margin="16px 0"
            >
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.nome?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("nome")}
                  name="nome"
                  type="text"
                  placeholder="Nome"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.nome?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Sexo:", value: "" },
                    { text: "Masculino", value: "M" },
                    { text: "Femino", value: "F" },
                  ]}
                  borderColor={
                    errors.sexo?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("sexo")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.sexo?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.dataNascimento?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("dataNascimento")}
                  type="date"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.dataNascimento?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <InputLineMask
                  mask="999.999.999-99"
                  borderColor={
                    errors.cpf?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("cpf")}
                  name="cpf"
                  type="text"
                  placeholder="Cpf"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cpf?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.nomeMae?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("nomeMae")}
                  name="nomeMae"
                  type="text"
                  placeholder="Nome da mãe"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.nomeMae?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Plano:", value: "" },
                    { text: "Example", value: "Example" },
                  ]}
                  borderColor={
                    errors.plano?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("plano")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.plano?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.valorPlano?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("valorPlano")}
                  name="valorPlano"
                  type="text"
                  placeholder="Valor do plano"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.valorPlano?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.descontoFolha?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("descontoFolha")}
                  name="descontoFolha"
                  type="text"
                  placeholder="Desconto"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.descontoFolha?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.dataInclusao?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("dataInclusao")}
                  name="dataInclusao"
                  type="date"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.dataInclusao?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <InputLineMask
                  mask="(99)9 9999-9999"
                  borderColor={
                    errors.telefoneContato?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("telefoneContato")}
                  name="telefoneContato"
                  type="text"
                  placeholder="Telefone Contato"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.telefoneContato?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.numeroMatricula?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("numeroMatricula")}
                  name="numeroMatricula"
                  type="text"
                  placeholder="Número da matrícula"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.numeroMatricula?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.observacoes?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("observacoes")}
                  name="observacoes"
                  type="text"
                  placeholder="Observações"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.observacoes?.message}
                </Text>
              </GridItem>
            </Grid>
            <DashboardButton type="submit" margin="0 auto">
              Enviar
            </DashboardButton>
          </TabPanel>
        </TabPanels>
      </form>
    </Tabs>
  );
};
