import { Button as ChakraButton } from "@chakra-ui/react";
import { ReactNode } from "react";
import { themeChakra } from "../../styles/theme-chakra";

type ButtonProps = {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
};

export const Button = ({ children, isLoading, loadingText }: ButtonProps) => {
  return (
    <ChakraButton
      isLoading={isLoading}
      loadingText={loadingText}
      marginTop="40px"
      borderRadius={7.45}
      textTransform="uppercase"
      color="white"
      padding={0}
      backgroundColor={themeChakra.colors.blue[600]}
      maxWidth={455.67}
      height={48.3}
      width="100%"
      _hover={{
        cursor: "pointer",
        opacity: "80%",
      }}
    >
      {" "}
      {children}
    </ChakraButton>
  );
};
