import { Link, ListItem } from "@chakra-ui/react";
import { SideItemProps } from "./Aside";

export const SideItem = ({ title, link }: SideItemProps) => {
  return (
    <ListItem
      color="white"
      margin="32px 0"
      borderBottom="1px solid rgb(255, 255, 255, 33%)"
    >
      <Link href={link}>{title}</Link>
    </ListItem>
  );
};
