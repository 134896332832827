import axios from "axios";

import { Cookies } from "react-cookie-consent";

const token = Cookies.get("portalx.token");

export const api = axios.create({
  baseURL: "http://127.0.0.1:8000",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
