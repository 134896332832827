import { Box, Flex, Link as ChakraLink } from "@chakra-ui/react";

import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { ConsultantsTable } from "./ConsultantsTable";
import { NextButton } from "../../components/next-button/NextButton";
import { ChangeEvent, useState } from "react";

export const Consultants = () => {
  const [term, setTerm] = useState<string | undefined>();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  return (
    <Box position="relative" height="100%" padding="64px 84px 16px 84px">
      <Flex justifyContent="space-between">
        <Box>
          <DashboardInput
            borderRadius="24px"
            onChange={handleInputChange}
            name="term"
            placeholder="Procurar ID ou Consultor..."
            type="search"
            icon="lupa.png"
            value={term}
          />
        </Box>
        <Box>
          <ChakraLink
            textDecoration="none"
            _hover={{
              textDecoration: "none",
            }}
            href="/consultores/cadastro"
          >
            <DashboardButton borderRadius="32px" icon="plus.png">
              Cadastrar Consultor
            </DashboardButton>
          </ChakraLink>
        </Box>
      </Flex>
      <Box>
        <ConsultantsTable term={term} />
      </Box>
      <NextButton marginRight="16px" marginBottom="16px">
        Avançar
      </NextButton>
    </Box>
  );
};
