import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";
import { themeChakra } from "../../styles/theme-chakra";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  size?: "lg" | "md" | "sm";
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, size = "lg", error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="gray.800" fontWeight="bold" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <ChakraInput
        {...rest}
        id={name}
        name={name}
        focusBorderColor="blue.900"
        ref={ref}
        fontWeight={themeChakra.fontWeights.light}
        color={themeChakra.colors.blue[600]}
        paddingTop="4px"
        paddingBottom="-10px"
        paddingLeft="4px"
        fontFamily={themeChakra.fonts.body}
        borderBottom={`1px solid ${themeChakra.colors.blue[600]}`}
        borderRadius="0"
        borderTop="none"
        borderRight="none"
        borderLeft="none"
        margin="16px 0"
        fontSize={15}
        _hover={{
          cursor: "initial",
        }}
        _focus={{
          borderColor: "transparent",
          boxShadow: "none",
          borderBottomColor: `${themeChakra.colors.blue[400]}`,
        }}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
