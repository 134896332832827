import { Box, Grid, GridItem } from "@chakra-ui/react";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";

import { NextButton } from "../../components/next-button/NextButton";
import { Select } from "../../components/select/Select";
import { CommissionsTable } from "./CommissionsTable";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

export const Commissions = () => {
  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        padding="64px 84px 16px 84px"
      >
        <Grid
          gap="8px"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(6, 1fr)"
        >
          <GridItem>
            <Select
              options={[
                { text: "Tipo:", value: "" },
                { text: "Externo", value: "externo" },
              ]}
              borderRadius="32px"
            />
          </GridItem>
          <GridItem>
            <Select
              options={[
                { text: "Operações:", value: "" },
                { text: "Externo", value: "externo" },
              ]}
              borderRadius="32px"
            />
          </GridItem>
          <GridItem>
            <Select
              options={[
                { text: "Consultores:", value: "" },
                { text: "Externo", value: "externo" },
              ]}
              borderRadius="32px"
            />
          </GridItem>
          <GridItem>
            <Select
              options={[
                { text: "Faixas da empresa:", value: "" },
                { text: "Externo", value: "externo" },
              ]}
              borderRadius="32px"
            />
          </GridItem>

          <DashboardInput
            width="100%"
            type="date"
            name="init"
            borderRadius="32px"
          />
          <DashboardInput type="date" name="valid" borderRadius="32px" />
          <GridItem colSpan={6}>
            <DashboardInput
              borderRadius="32px"
              name="observacoes"
              type="text"
              placeholder="Observações"
            />
          </GridItem>
          <GridItem colSpan={6} height="100%">
            <DashboardButton type="submit" margin="0 auto">
              Enviar
            </DashboardButton>
            <CommissionsTable />
          </GridItem>
        </Grid>

        <NextButton marginRight="16px" marginBottom="16px">
          Avançar
        </NextButton>
      </Box>
    </>
  );
};
