import { Grid, GridItem, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { themeChakra } from "../../styles/theme-chakra";
import { InputLineMask } from "../../components/input-mask/InputMask";
import { Select } from "../../components/select/Select";

export interface FormData {
  name: string;
  cpf: string;
  rg: string;
  email: string;
  celular: string;
  example: string;
  endereco: string;
  vinculo: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-ZÀ-ú\s]*$/, "Insira um nome válido")
    .required("O nome é obrigatório"),
  cpf: yup.string().required("CPF é obrigatório"),
  rg: yup.string().required("RG é obrigatório"),
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  celular: yup.string().required("Celular é obrigatório"),
  endereco: yup.string().required("Endereço é obrigatório"),
  vinculo: yup.string().required("Vínculo é obrigatório"),
});

export const ConsultantRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        gap="16px 20px"
        padding="64px 84px 16px 84px"
        templateColumns="repeat(4, 1fr)"
        templateRows="repeat(4, 1fr)"
      >
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("name")}
            borderRadius="8px"
            name="name"
            placeholder="Nome"
            type="text"
            borderColor={
              errors.name?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.name?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <InputLineMask
            placeholder="CPF"
            {...register("cpf")}
            mask="999.999.999-99"
            borderColor={
              errors.cpf?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>{errors.cpf?.message}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("rg")}
            borderRadius="8px"
            name="rg"
            placeholder="RG"
            type="text"
            borderColor={
              errors.rg?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>{errors.rg?.message}</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("email")}
            borderRadius="8px"
            name="email"
            placeholder="Email"
            type="text"
            borderColor={
              errors.email?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.email?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <InputLineMask
            placeholder="Celular"
            {...register("celular")}
            mask="(99) 9 9999-9999"
            borderColor={
              errors.celular?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>{errors.celular?.message}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Select
            options={[
              { text: "Selecione:", value: "" },
              { text: "Externo", value: "externo" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.vinculo?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("vinculo")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.vinculo?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={4}>
          <DashboardInput
            {...register("endereco")}
            borderRadius="8px"
            name="endereco"
            placeholder="Endereço"
            type="text"
            borderColor={
              errors.endereco?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.endereco?.message}
          </Text>
        </GridItem>
      </Grid>
      <DashboardButton type="submit" margin="0 auto">
        Enviar
      </DashboardButton>
    </form>
  );
};

/*

 
 */
