import { useState } from "react";
import { Box, Grid, GridItem, Link } from "@chakra-ui/react";
import { NextButton } from "../../components/next-button/NextButton";
import { Select } from "../../components/select/Select";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";
import { SalesTable } from "./SalesTable";

export const Sales = () => {
  const [segment, setSegment] = useState("");
  const [period, setPeriod] = useState("");
  const [consultor, setConsultor] = useState("");

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        padding="64px 84px 16px 84px"
      >
        <Grid
          gap="8px"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(4, 1fr)"
        >
          <GridItem>
            <Select
              options={[
                { text: "Consultor:", value: "" },
                { text: "Todos:", value: "todos" },
              ]}
              borderRadius="32px"
              onChange={(e) => setSegment(e.target.value)}
            />
          </GridItem>
          <GridItem>
            <Select
              options={[
                { text: "Plano:", value: "" },
                {
                  text: "MEI (3 a 99 vidas)/Integral DOC LE/Compulsória",
                  value: "MEI (3 a 99 vidas)/Integral DOC LE/Compulsória",
                },
                {
                  text: "(3 a 29 vidas)/Integral DOC LE/Compulsória",
                  value: "(3 a 29 vidas)/Integral DOC LE/Compulsória",
                },
                {
                  text: "(3 a 29 vidas)/Integral DOC LE/Livre adesão",
                  value: "(3 a 29 vidas)/Integral DOC LE/Livre adesão",
                },
                {
                  text: "(3 a 99 vidas)/Master LE/Livre adesão",
                  value: "(3 a 99 vidas)/Master LE/Livre adesão",
                },
              ]}
              borderRadius="32px"
              onChange={(e) => setPeriod(e.target.value)}
            />
          </GridItem>
          <GridItem>
            <Select
              options={[{ text: "Indicador:", value: "" }]}
              borderRadius="32px"
              onChange={(e) => setConsultor(e.target.value)}
            />
          </GridItem>
          <GridItem display="flex" justifyContent="end">
            <Link
              _hover={{
                textDecoration: "none",
              }}
              href="/vendas/cadastro"
            >
              <DashboardButton borderRadius="32px" icon="plus.png">
                Cadastrar Meta
              </DashboardButton>
            </Link>
          </GridItem>
        </Grid>

        <GridItem>
          <SalesTable segment={segment} period={period} consultor={consultor} />
        </GridItem>

        <NextButton marginRight="16px" marginBottom="16px">
          Avançar
        </NextButton>
        <NextButton marginLeft="16px" marginBottom="16px">
          Voltar
        </NextButton>
      </Box>
    </>
  );
};
