import { useState } from "react";
import { data } from "../../../data/mockConsultants";

export function useDeleteConsultant() {
  const [isDeleteLoading, setIsLoading] = useState(false);
  const deleteConsultant = async (id: number) => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.delete("company/locator/list/id");  //example
      const newData = data.filter((data) => data.id !== id);
      return newData;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteConsultant, isDeleteLoading };
}
