import { Button } from "@chakra-ui/react";
import { ReactNode } from "react";
import { themeChakra } from "../../styles/theme-chakra";

type NextButtonProps = {
  children: ReactNode;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  marginTop?: string;
};

export const NextButton = ({
  children,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
}: NextButtonProps) => {
  return (
    <Button
      right={marginRight}
      bottom={marginBottom}
      left={marginLeft}
      top={marginTop}
      position="absolute"
      fontWeight={themeChakra.fontWeights.bold}
      margin="29px"
      borderRadius="22px"
      background="white"
      boxShadow="0px 5px 5px #C5C5C5"
      _hover={{
        cursor: "pointer",
      }}
    >
      {children}
    </Button>
  );
};
