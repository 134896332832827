import { SalesDTO } from "../dtos/sales";

export const data: SalesDTO[] = [
  {
    consultor: "Gustavo Batista",
    plano: "MEI (3 a 99 vidas)/Integral DOC LE/Compulsória",
    indicador: "3 vidas",
    meta: 20,
    intervalo: "Semanal",
    edit: true,
  },
  {
    consultor: "Agnaldo Pereira",
    plano: "(3 a 29 vidas)/Integral DOC LE/Compulsória",
    indicador: "29 vidas",
    meta: 20,
    intervalo: "Semanal",
    edit: true,
  },
  {
    consultor: "Davi Silva Borges",
    plano: "(3 a 29 vidas)/Integral DOC LE/Livre adesão",
    indicador: "28 vidas",
    meta: 20,
    intervalo: "Semanal",
    edit: true,
  },
  {
    consultor: "Todos",
    plano: "(3 a 29 vidas)/Master LE/Compulsória",
    indicador: "29 vidas",
    meta: 20,
    intervalo: "Semanal",
    edit: true,
  },
  {
    consultor: "Todos",
    plano: "(3 a 99 vidas)/Master LE/Livre adesão",
    indicador: "3 vidas",
    meta: 20,
    intervalo: "Semanal",
    edit: true,
  },
];
