import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { themeChakra } from "../../styles/theme-chakra";
import { Header } from "../header/Header";
import { Aside } from "../aside/Aside";

type Props = {
  children: React.ReactNode;
  path?: string;
};

export function AuthTemplate({ children }: Props) {
  return (
    <Flex direction="column" minHeight="100vh">
      <Header />
      <Flex minHeight="" direction="column" width="100%">
        <Flex
          borderRadius="0 64px 0 0"
          background={themeChakra.colors.blue[600]}
          width="100%"
          px="0"
          mx="auto"
          minHeight="calc(100vh - 99.11px)"
        >
          <Text
            alignItems="center"
            position="absolute"
            left="465px"
            transform="translateX(-50%)"
            margin="0 auto"
            as="h1"
            marginTop="16px"
            color="white"
            fontSize="24px"
          >
            PAINEL GERENCIAL
          </Text>
          <Aside />
          <Box
            position="relative"
            margin="64px 0 0 0"
            bg="white"
            width="full"
            borderWidth={1}
          >
            {children}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
