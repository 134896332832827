import { Text } from "@chakra-ui/react";
import { ReactNode } from "react";

import { themeChakra } from "../../styles/theme-chakra";

type TitleProps = {
  children: ReactNode;
};

export const Title = ({ children }: TitleProps) => {
  return (
    <Text
      textAlign="center"
      margin={4}
      letterSpacing="4px"
      fontFamily={themeChakra.fonts.heading}
      fontSize={44}
      color={themeChakra.colors.blue[600]}
      fontWeight={themeChakra.fontWeights.light}
    >
      {children}
    </Text>
  );
};
