import { useState } from "react";

import { data as dataClients } from "../../../data/mockClients";
import { ClientDTO } from "../../../dtos/client";

export function useListClients() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ClientDTO[]>([]);

  const getClients = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataClients);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getClients, data, setData };
}
