import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Grid,
  Box,
  Img,
  useMediaQuery,
  Link,
} from "@chakra-ui/react";
import { Title } from "../../components/title/Title";
import { themeChakra } from "../../styles/theme-chakra";

import { Input } from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import { AuthLabel } from "../../components/label/Label";

type Form = {
  password: string;
  passwordConfirm: string;
};

export const UpdatePassword = () => {
  const createFormSchema = yup.object({
    password: yup
      .string()
      .required("Por favor, insira sua senha")
      .min(8, "Mínimo 8 caracteres"),
    passwordConfirm: yup
      .string()
      .required("Por favor, insira sua senha")
      .min(8, "Mínimo 8 caracteres")
      .test("passwords-match", "As senhas devem ser iguais", function (value) {
        return this.parent.password === value;
      }),
  });

  const { register, formState, handleSubmit } = useForm<Form>({
    mode: "onChange",
    resolver: yupResolver(createFormSchema),
  });

  const { errors } = formState;

  const [isLargerThan767] = useMediaQuery("(min-width: 767px)");

  const handleSend: SubmitHandler<Form> = async (values) => {
    setTimeout(() => {
      console.log(values);
    }, 2000);
  };

  return (
    <Grid
      templateColumns={isLargerThan767 ? "1.1fr 1fr" : "1fr"}
      gap={0}
      minHeight="100vh"
    >
      {isLargerThan767 && (
        <Box position="relative">
          <Img
            src="/mulher.png"
            alt="Imagem"
            objectFit="cover"
            w="100%"
            h="100vh"
          />
        </Box>
      )}

      <Flex direction="column" justify="center" align="center">
        <Box
          position="relative"
          width={isLargerThan767 ? "70%" : "100%"}
          maxWidth={500}
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={isLargerThan767 ? 8 : 2}
        >
          <Title>ALTERAR</Title>

          <Flex
            width="100%"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(handleSend)}
          >
            <FormControl isInvalid={!!errors.password}>
              <Stack>
                <AuthLabel htmlFor="password">Senha</AuthLabel>
                <Input type="password" {...register("password")} />
                <FormErrorMessage
                  color={themeChakra.colors.red[200]}
                  marginTop="-16px"
                >
                  {errors.password?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>
            <FormControl isInvalid={!!errors.passwordConfirm}>
              <Stack>
                <AuthLabel htmlFor="passwordConfirm">Senha novamente</AuthLabel>
                <Input type="password" {...register("passwordConfirm")} />
                <FormErrorMessage
                  color={themeChakra.colors.red[200]}
                  marginTop="-16px"
                >
                  {errors.passwordConfirm?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>

            <Button>Enviar</Button>
          </Flex>

          <Link
            href="/recuperar"
            margin="16px 0"
            color={themeChakra.colors.red[200]}
          >
            Não lembro meu email
          </Link>
        </Box>
      </Flex>
    </Grid>
  );
};
