import {
  Grid,
  GridItem,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { themeChakra } from "../../styles/theme-chakra";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputLineMask } from "../../components/input-mask/InputMask";
import { Select } from "../../components/select/Select";

export interface FormData {
  operadora: string;
  tipo: string;
  numeroProposta: string;
  faixa: string;
  formaContribuicao: string;
  codigoCliente: string;
  vigencia: string;
  statusContrato: string;
  motivoCancelamento: string;
  consultorResponsavel: string;
  observacoes: string;
  planoIntegral: string;
  planoMaster: string;
  planoPF: string;
  precoUnitarioIntegral: string;
  precoUnitarioMaster: string;
  dataVenda: string;
  valorContrato: string;
  comissaoConsultor: string;
  contratoSocial: FileList;
  cartaoCnpj: string;
  documentoFotoRepresentante: string;
}

export const ContractRegister = () => {
  const schema = yup.object().shape({
    operadora: yup.string().required("Campo obrigatório"),
    tipo: yup.string().required("Campo obrigatório"),
    numeroProposta: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^[0-9]+$/, "Campo deve conter apenas números"),
    faixa: yup.string().required("Campo obrigatório"),
    formaContribuicao: yup.string().required("Campo obrigatório"),
    codigoCliente: yup.string().required("Campo obrigatório"),
    vigencia: yup.string().required("Campo obrigatório"),
    statusContrato: yup.string().required("Campo obrigatório"),
    motivoCancelamento: yup.string().required("Campo obrigatório"),
    consultorResponsavel: yup.string().required("Campo obrigatório"),
    observacoes: yup.string().required("Campo obrigatório"),
    planoIntegral: yup.string().required("Campo obrigatório"),
    planoMaster: yup.string().required("Campo obrigatório"),
    planoPF: yup.string().required("Campo obrigatório"),
    precoUnitarioIntegral: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^[0-9]+$/, "Campo deve conter apenas números"),
    precoUnitarioMaster: yup
      .string()
      .required("Email é obrigatório")
      .matches(/^[0-9]+$/, "Campo deve conter apenas números"),
    dataVenda: yup.string().required("Campo obrigatório"),

    valorContrato: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^[0-9]+$/, "Campo deve conter apenas números"),
    comissaoConsultor: yup.string().required("Campo obrigatório"),
    contratoSocial: yup
      .mixed()
      .test("is-file", "Campo obrigatório", function (value) {
        if (value instanceof FileList) {
          return value.length > 0;
        }
        return false;
      }),
    cartaoCnpj: yup
      .mixed()
      .test("is-file", "Campo obrigatório", function (value) {
        if (value instanceof FileList) {
          return value.length > 0;
        }
        return false;
      }),
    documentoFotoRepresentante: yup
      .mixed()
      .test("is-file", "Campo obrigatório", function (value) {
        if (value instanceof FileList) {
          return value.length > 0;
        }
        return false;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <Tabs
      padding="64px 84px 16px 84px"
      justifyContent="center"
      position="relative"
      variant="unstyled"
    >
      <TabList>
        <Tab>Primeira Etapa</Tab>
        <Tab>Segunda Etapa</Tab>
        <Tab>Terceira Etapa</Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanels>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Informações Contratuais
            </Text>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(4, 1fr)"
            >
              <GridItem colSpan={1}>
                <Select
                  borderColor={
                    errors.operadora?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("operadora")}
                  options={[
                    { text: "Operadora:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.operadora?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.tipo?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("tipo")}
                  type="text"
                  placeholder="Tipo:"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.tipo?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.numeroProposta?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("numeroProposta")}
                  name="numeroProposta"
                  type="text"
                  placeholder="Número da Proposta"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.numeroProposta?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Faixa:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.faixa?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("faixa")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.faixa?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Forma de Contribuição:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.formaContribuicao?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("formaContribuicao")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.formaContribuicao?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.codigoCliente?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("codigoCliente")}
                  name="codigoCliente"
                  type="text"
                  placeholder="Código de Cliente"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.codigoCliente?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <InputLineMask
                  borderColor={
                    errors.vigencia?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  mask="99999-999"
                  {...register("vigencia")}
                  name="vigencia"
                  type="text"
                  placeholder="Vigência"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.vigencia?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Status do Contrato:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.statusContrato?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("statusContrato")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.statusContrato?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Motivo do Cancelamento:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.motivoCancelamento?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("motivoCancelamento")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.motivoCancelamento?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Consultor Responsável:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.consultorResponsavel?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("consultorResponsavel")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.consultorResponsavel?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={2}>
                <DashboardInput
                  borderColor={
                    errors.observacoes?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("observacoes")}
                  name="observacoes"
                  type="text"
                  placeholder="Observacoes"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.observacoes?.message}
                </Text>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Planos Contratados
            </Text>
            <Grid
              gap="16px 20px"
              marginBottom="16px"
              templateColumns="repeat(4, 1fr)"
              templateRows="repeat(1, 1fr)"
            >
              <GridItem colSpan={2}>
                <Select
                  options={[
                    { text: "Plano Integral:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.planoIntegral?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("planoIntegral")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.planoIntegral?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={2}>
                <Select
                  options={[
                    { text: "Plano Master:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.planoMaster?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("planoMaster")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.planoMaster?.message}
                </Text>
              </GridItem>
            </Grid>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(2, 1fr)"
            >
              <GridItem colSpan={1}>
                <Select
                  options={[
                    { text: "Plano Pessoa Física:", value: "" },
                    { text: "example", value: "example" },
                  ]}
                  borderColor={
                    errors.planoPF?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("planoPF")}
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.planoPF?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.precoUnitarioIntegral?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("precoUnitarioIntegral")}
                  name="precoUnitarioIntegral"
                  type="text"
                  placeholder="Preço Unitário Integral"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.precoUnitarioIntegral?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.precoUnitarioMaster?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("precoUnitarioMaster")}
                  name="precoUnitarioMaster"
                  type="text"
                  placeholder="Preço Unitário Master"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.precoUnitarioMaster?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.dataVenda?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("dataVenda")}
                  name="dataVenda"
                  type="date"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.dataVenda?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.valorContrato?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("valorContrato")}
                  name="valorContrato"
                  type="text"
                  placeholder="Valor do Contrato"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.valorContrato?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.comissaoConsultor?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("comissaoConsultor")}
                  name="comissaoConsultor"
                  type="text"
                  placeholder="Comissão do Consultor"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.comissaoConsultor?.message}
                </Text>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Representante e responsável
            </Text>
            <Grid
              margin="16px 0"
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(1, 1fr)"
            >
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.contratoSocial?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("contratoSocial")}
                  name="contratoSocial"
                  type="file"
                  placeholder="Contrato Social"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.contratoSocial?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.cartaoCnpj?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("cartaoCnpj")}
                  name="cartaoCnpj"
                  type="file"
                  placeholder="Cartão CNPJ"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cartaoCnpj?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.documentoFotoRepresentante?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("documentoFotoRepresentante")}
                  name="documentoFotoRepresentante"
                  type="file"
                  placeholder="Documento do Representante"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.documentoFotoRepresentante?.message}
                </Text>
              </GridItem>
            </Grid>
            <DashboardButton type="submit" margin="0 auto">
              Enviar
            </DashboardButton>
          </TabPanel>
        </TabPanels>
      </form>
    </Tabs>
  );
};
