import { Grid, GridItem, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { themeChakra } from "../../styles/theme-chakra";

import { Select } from "../../components/select/Select";

export interface FormData {
  faixaEmpresa: string;
  tipo: string;
  operador: string;
  data: string;
  Plano: string;
  contribuicao: string;
  preco: number;
  validade: string;
  carencias: string;
}
//, tipo, preço, validade, observação, plano do beneficio, contribuição  form, operation company

const schema = yup.object().shape({
  faixaEmpresa: yup.string().required("Faixa é obrigatório"),
  tipo: yup.string().required("Tipo é obrigatório"),
  operador: yup.string().required("Operador é obrigatório"),
  plano: yup.string().required("Plano é obrigatório"),
  contribuicao: yup.string().required("Contribuição é obrigatório"),
  data: yup.string().required("Data é obrigatório"),
  preco: yup
    .string()
    .matches(/^\d+$/, "Deve conter apenas números")
    .required("Preço é obrigatório"),
  validade: yup.string().required("Validade é obrigatório"),
  carencias: yup.string().required("Carência é obrigatório"),
});

export const PlanRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        gap="16px 20px"
        padding="64px 84px 16px 84px"
        templateColumns="repeat(4, 1fr)"
        templateRows="repeat(4, 1fr)"
      >
        <GridItem colSpan={3}>
          <Select
            options={[
              { text: "Faixa da empresa:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.faixaEmpresa?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
            {...register("faixaEmpresa")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.faixaEmpresa?.message}
          </Text>
        </GridItem>

        <GridItem colSpan={1}>
          <Select
            options={[
              { text: "Tipo:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.tipo?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("tipo")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.tipo?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Select
            options={[
              { text: "Operador:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.operador?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("operador")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.operador?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select
            options={[
              { text: "Plano:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.plano?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("plano")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.plano?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select
            options={[
              { text: "Contribuição:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.contribuicao?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
            {...register("contribuicao")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.contribuicao?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("data")}
            borderRadius="8px"
            type="date"
            borderColor={
              errors.data?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.data?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("preco")}
            borderRadius="8px"
            placeholder="Preço"
            type="text"
            borderColor={
              errors.preco?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.preco?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("validade")}
            borderRadius="8px"
            type="date"
            borderColor={
              errors.validade?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.validade?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("carencias")}
            borderRadius="8px"
            type="text"
            placeholder="Carências e condições"
            borderColor={
              errors.carencias?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.carencias?.message}
          </Text>
        </GridItem>
      </Grid>
      <DashboardButton type="submit" margin="0 auto">
        Enviar
      </DashboardButton>
    </form>
  );
};

/*

 
 */
