import { PlansDTO } from "../dtos/plans";

export const data: PlansDTO[] = [
  {
    id: 1,
    faixaEmpresa: "MEI (3 a 99 vidas)",
    nomePlano: "Integral DOC LE",
    contribuicao: "Compulsória",
    preco: 3200,
    celular: "(73) 9 9999-9999",
    data: "2024-12-31",
    edit: true,
  },
];
