import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";

export type DashboardInputProps = {
  type: string;
  placeholder?: string;
  icon?: string;
  name: string;
  width?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  borderRadius?: string;
  borderColor?: string;
  margin?: string;
  display?: "block" | "none";
};

const DashboardInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  DashboardInputProps
> = (
  { onChange, name, placeholder, icon, borderColor, margin, display, ...rest },
  ref
) => {
  return (
    <InputGroup borderColor={borderColor || "black"}>
      <Input
        {...rest}
        display={display || "block"}
        onChange={onChange}
        fontSize={14}
        name={name}
        placeholder={placeholder}
        ref={ref}
        margin={margin}
        _hover={{
          cursor: "initial",
        }}
      />
      <InputRightElement pointerEvents="none">
        {icon && <img src={`/${icon}`} alt="" />}
      </InputRightElement>
    </InputGroup>
  );
};

export const DashboardInput = forwardRef(DashboardInputBase);
