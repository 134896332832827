import { Box, Flex, Link as ChakraLink, Link } from "@chakra-ui/react";

import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { NextButton } from "../../components/next-button/NextButton";
import { ChangeEvent, useState } from "react";
import { DependentsTable } from "./DependentsTable";

export const Dependents = () => {
  const [term, setTerm] = useState<string | undefined>();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  return (
    <Box position="relative" height="100%" padding="64px 84px 16px 84px">
      <Flex justifyContent="space-between">
        <Box>
          <DashboardInput
            borderRadius="24px"
            onChange={handleInputChange}
            name="term"
            placeholder="Procurar..."
            type="search"
            icon="lupa.png"
            value={term}
          />
        </Box>
        <Box>
          <ChakraLink
            textDecoration="none"
            _hover={{
              textDecoration: "none",
            }}
            href="/consultores/cadastro"
          >
            <Link href="/dependentes/cadastro" _hover={{textDecoration: 'none'}}>
              <DashboardButton borderRadius="32px" icon="plus.png">
                Cadastrar Dependente
              </DashboardButton>
            </Link>
          </ChakraLink>
        </Box>
      </Flex>
      <Box>
        <DependentsTable term={term} />
      </Box>
      <NextButton marginRight="16px" marginBottom="16px">
        Avançar
      </NextButton>
    </Box>
  );
};
