import { useState } from "react";

import { data as dataComissions } from "../../../data/mockCommissions";
import { CommissionsDTO } from "../../../dtos/commissions";

export function useListCommissions() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CommissionsDTO[]>([]);

  const getCommissions = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataComissions);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getCommissions, data, setData };
}
