import { Flex, Img, Link, Text } from "@chakra-ui/react";
import { themeChakra } from "../../styles/theme-chakra";

export const Header = () => {
  return (
    <Flex
      padding="13px 64px"
      as="header"
      maxHeight="99.11"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex as="section">
        <Link href="/">
          <Img src="/logo.png" />
        </Link>
      </Flex>
      <Flex width="100%" flexDirection="column" as="div">
        <Text
          as="h1"
          fontSize="24px"
          fontFamily={themeChakra.fonts.heading}
          textAlign="center"
          color={themeChakra.colors.blue[600]}
          fontWeight={themeChakra.fontWeights.bold}
        >
          PORTAL EXTREMO SUL CORRETORA
        </Text>
        <Text
          fontWeight={themeChakra.fontWeights.bold}
          color={themeChakra.colors.blue[600]}
          textAlign="center"
        >
          Planos de Saúde, Planos Odontológicos e Seguros de vida
        </Text>
      </Flex>
    </Flex>
  );
};
