import {
  Grid,
  GridItem,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { themeChakra } from "../../styles/theme-chakra";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputLineMask } from "../../components/input-mask/InputMask";

export interface FormData {
  razao: string;
  cnpj: string;
  data: string;
  nomeFantasia: string;
  ramo: string;
  inscricao: string;
  cep: string;
  rua: string;
  numero: string;
  complemento?: string;
  bairro: string;
  municipio: string;
  uf: string;
  telefone: string;
  celular: string;
  email: string;
  cpfRepresentante: string;
  nomeRepresentante: string;
  responsavelContato: string;
}

export const ClientRegister = () => {
  const schema = yup.object().shape({
    razao: yup.string().required("Campo obrigatório"),
    cnpj: yup.string().required("Campo obrigatório"),
    data: yup.string().required("Campo obrigatório"),
    nomeFantasia: yup.string().required("Campo obrigatório"),
    ramo: yup.string().required("Campo obrigatório"),
    inscricao: yup.string().required("Campo obrigatório"),
    cep: yup.string().required("Campo obrigatório"),
    rua: yup.string().required("Campo obrigatório"),
    numero: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^[0-9]+$/, "Telefone deve conter apenas números"),
    complemento: yup.string(),
    bairro: yup.string().required("Campo obrigatório"),
    municipio: yup.string().required("Campo obrigatório"),
    uf: yup
      .string()
      .required("Campo obrigatório")
      .max(2, "UF deve ter no máximo 2 caracteres."),
    telefone: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^[0-9]+$/, "Telefone deve conter apenas números"),
    celular: yup.string().required("Campo obrigatório"),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    cpfRepresentante: yup.string().required("Campo obrigatório"),
    nomeRepresentante: yup.string().required("Campo obrigatório"),
    responsavelContato: yup.string().required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <Tabs
      padding="64px 84px 16px 84px"
      justifyContent="center"
      position="relative"
      variant="unstyled"
    >
      <TabList>
        <Tab>Primeira Etapa</Tab>
        <Tab>Segunda Etapa</Tab>
        <Tab>Terceira Etapa</Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanels>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Dados da Empresa/Cliente
            </Text>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(2, 1fr)"
            >
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.razao?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("razao")}
                  name="razao"
                  type="text"
                  placeholder="Razão Social"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.razao?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <InputLineMask
                  borderColor={
                    errors.cnpj?.message ? themeChakra.colors.red[200] : "black"
                  }
                  mask="99.999.999/0001-99"
                  {...register("cnpj")}
                  name="cnpj"
                  type="text"
                  placeholder="CNPJ/MF"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cnpj?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.data?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("data")}
                  name="data"
                  type="date"
                  placeholder="Data de Nascimento/Abertura"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.data?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.nomeFantasia?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("nomeFantasia")}
                  name="nomeFantasia"
                  type="text"
                  placeholder="Nome Fantasia"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.nomeFantasia?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.ramo?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("ramo")}
                  name="ramo"
                  type="text"
                  placeholder="Ramo de atividade"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.ramo?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.inscricao?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("inscricao")}
                  name="inscricao"
                  type="text"
                  placeholder="Inscrição Estadual"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.inscricao?.message}
                </Text>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Endereco e Contatos
            </Text>
            <Grid
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(4, 1fr)"
            >
              <GridItem colSpan={1}>
                <InputLineMask
                  borderColor={
                    errors.cep?.message ? themeChakra.colors.red[200] : "black"
                  }
                  mask="99999-999"
                  {...register("cep")}
                  name="cep"
                  type="text"
                  placeholder="Cep"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cep?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.rua?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("rua")}
                  name="rua"
                  type="text"
                  placeholder="Rua"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.rua?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.numero?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("numero")}
                  name="numero"
                  type="text"
                  placeholder="Número"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.numero?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.complemento?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("complemento")}
                  name="complemento"
                  type="text"
                  placeholder="Complemento"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.complemento?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.bairro?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("bairro")}
                  name="bairro"
                  type="text"
                  placeholder="Bairro"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.bairro?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.municipio?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("municipio")}
                  name="municipio"
                  type="text"
                  placeholder="Município"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.municipio?.message}
                </Text>
              </GridItem>

              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.uf?.message ? themeChakra.colors.red[200] : "black"
                  }
                  {...register("uf")}
                  name="uf"
                  type="text"
                  placeholder="UF"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.uf?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={2}>
                <DashboardInput
                  borderColor={
                    errors.telefone?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("telefone")}
                  name="telefone"
                  type="text"
                  placeholder="Telefone"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.telefone?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={2}>
                <InputLineMask
                  borderColor={
                    errors.celular?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  mask="(99) 9 9999-9999"
                  {...register("celular")}
                  name="celular"
                  type="text"
                  placeholder="Celular"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.celular?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.email?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("email")}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.email?.message}
                </Text>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Text
              margin="16px 0"
              color={themeChakra.colors.blue[600]}
              fontSize="22px"
              fontFamily={themeChakra.fonts.heading}
              fontWeight={themeChakra.fontWeights.bold}
            >
              Representante e responsável
            </Text>
            <Grid
              margin="16px 0"
              gap="16px 20px"
              templateColumns="repeat(3, 1fr)"
              templateRows="repeat(1, 1fr)"
            >
              <GridItem colSpan={1}>
                <InputLineMask
                  borderColor={
                    errors.cpfRepresentante?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  mask="999.999.999-99"
                  {...register("cpfRepresentante")}
                  name="cpfRepresentante"
                  type="text"
                  placeholder="CPF do Representante Legal"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.cpfRepresentante?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.nomeRepresentante?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("nomeRepresentante")}
                  name="nomeRepresentante"
                  type="text"
                  placeholder="Nome do Representante"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.nomeRepresentante?.message}
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <DashboardInput
                  borderColor={
                    errors.responsavelContato?.message
                      ? themeChakra.colors.red[200]
                      : "black"
                  }
                  {...register("responsavelContato")}
                  name="responsavelContato"
                  type="text"
                  placeholder="Responsável para Contatos"
                />
                <Text color={themeChakra.colors.red[200]}>
                  {errors.responsavelContato?.message}
                </Text>
              </GridItem>
            </Grid>
            <DashboardButton type="submit" margin="0 auto">
              Enviar
            </DashboardButton>
          </TabPanel>
        </TabPanels>
      </form>
    </Tabs>
  );
};
