import { useState } from "react";

import { PlansDTO } from "../../../dtos/plans";

import { data as dataPlans } from "../../../data/mockPlans";

export function useListPlans() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PlansDTO[]>([]);

  const getPlans = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataPlans);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getPlans, data, setData };
}
