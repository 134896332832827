import { useState } from "react";

import { SalesDTO } from "../../../dtos/sales";
import { data as dataSales } from "../../../data/mockSales";

export function useListSales() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SalesDTO[]>([]);

  const getSales = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataSales);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getSales, data, setData };
}
