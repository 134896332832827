import { Grid, GridItem, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { themeChakra } from "../../styles/theme-chakra";

import { Select } from "../../components/select/Select";

export interface FormData {
  consultor: string;
  meta: string;
  indicador: string;
  plano: string;
}

const schema = yup.object().shape({
  consultor: yup.string().required("Consultor é obrigatório"),
  meta: yup.string().required("Meta é obrigatório"),
  indicador: yup.string().required("Indicador é obrigatório"),
  plano: yup.string().required("Plano é obrigatório"),
});

export const SaleRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        gap="16px 20px"
        padding="64px 84px 16px 84px"
        templateColumns="repeat(4, 1fr)"
        templateRows="repeat(2, 1fr)"
      >
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("consultor")}
            borderRadius="8px"
            name="consultor"
            placeholder="Consultor"
            type="text"
            borderColor={
              errors.consultor?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.consultor?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("meta")}
            borderRadius="8px"
            name="meta"
            placeholder="Meta"
            type="text"
            borderColor={
              errors.meta?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.meta?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Select
            options={[
              { text: "Indicador:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.indicador?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("indicador")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.indicador?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Select
            options={[
              { text: "Plano:", value: "" },
              { text: "Example", value: "example" },
            ]}
            borderRadius="8px"
            borderColor={
              errors.plano?.message ? themeChakra.colors.red[200] : "black"
            }
            {...register("plano")}
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.plano?.message}
          </Text>
        </GridItem>
      </Grid>
      <DashboardButton type="submit" margin="0 auto">
        Enviar
      </DashboardButton>
    </form>
  );
};

/*

 
 */
