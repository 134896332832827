import { CommissionsDTO } from "../dtos/commissions";

export const data: CommissionsDTO[] = [
  {
    id: 1,
    consultor: "Todos",
    parcela: "4º Parcela",
    corretoraPorcentagem: "0%",
    consultorPorcentagem: "100%",
    desconto: "-80%",
    edit: true,
  },
  {
    id: 2,
    consultor: "Todos",
    parcela: "4º Parcela",
    corretoraPorcentagem: "0%",
    consultorPorcentagem: "100%",
    desconto: "-80%",
    edit: true,
  },
];
