import { extendTheme } from "@chakra-ui/react";

export const themeChakra = extendTheme({
  colors: {
    gray: {
      50: "#F8FAFC",
      300: "#D9D9D9",
    },
    green: {
      50: "#EBEEF4",
      300: "#0AB525",
    },

    blue: {
      400: "#5B94CD",
      600: "#0A60B5",
      700: "#004181",
    },

    black: {
      700: "#666666",
    },

    red: {
      200: "#D08E8E",
      500: "#DA1924",
    },

    purple: {
      300: "#88B0FF",
    },
  },

  fonts: {
    heading: "Inter",
    body: "Sofia Sans",
  },
  fontWeights: {
    light: 200,
    medium: 500,
    bold: 700,
  },
  styles: {
    global: {
      html: {
        fontFamily: "'Sofia Sans', sans-serif",
      },
      body: {
        margin: 0,
        padding: 0,
        bg: "white",
      },

      "::-webkit-input-placeholder": {
        color: "#828282",
      },
      "::-moz-placeholder": {
        color: "#828282",
      },
      ":-ms-input-placeholder": {
        color: "#828282",
      },
    },
  },
});
