import { Grid, GridItem, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DashboardInput } from "../../components/dashboard-input/DashboardInput";
import { DashboardButton } from "../../components/dashboard-button/DashboardButton";

import { themeChakra } from "../../styles/theme-chakra";

export interface FormData {
  title: string;
  porcentagemDesconto: string;
  precoUnitario: string;
  precoPromocional: string;
  inicioPromocao: string;
  finalPromocao: string;
  criteriosCondicoes: string;
}

const schema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
  porcentagemDesconto: yup
    .string()
    .required("Campo obrigatório")
    .matches(/^[0-9]+$/, "Por favor, insira apenas números"),
  precoUnitario: yup
    .string()
    .required("Campo obrigatório")
    .matches(/^[0-9]+$/, "Por favor, insira apenas números"),
  precoPromocional: yup
    .string()
    .required("Campo obrigatório")
    .matches(/^[0-9]+$/, "Por favor, insira apenas números"),
  inicioPromocao: yup.string().required("Campo obrigatório"),
  fimPromocao: yup.string().required("Campo obrigatório"),
  criteriosCondicoes: yup.string().required("Campo obrigatório"),
});

export const PromotionRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        gap="16px 20px"
        padding="64px 84px 16px 84px"
        templateColumns="repeat(4, 1fr)"
        templateRows="repeat(2, 1fr)"
      >
        <GridItem colSpan={4}>
          <DashboardInput
            {...register("title")}
            borderRadius="8px"
            name="title"
            placeholder="Título"
            type="text"
            borderColor={
              errors.title?.message ? themeChakra.colors.red[200] : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.title?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("porcentagemDesconto")}
            borderRadius="8px"
            name="porcentagemDesconto"
            placeholder="Desconto"
            type="text"
            borderColor={
              errors.porcentagemDesconto?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />
          <Text color={themeChakra.colors.red[200]}>
            {errors.porcentagemDesconto?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("precoUnitario")}
            borderRadius="8px"
            name="precoUnitario"
            placeholder="Preço unitário"
            type="text"
            borderColor={
              errors.precoUnitario?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.precoUnitario?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <DashboardInput
            {...register("precoPromocional")}
            borderRadius="8px"
            name="precoPromocional"
            placeholder="Preço promocional"
            type="text"
            borderColor={
              errors.precoPromocional?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.precoPromocional?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <DashboardInput
            {...register("inicioPromocao")}
            borderRadius="8px"
            name="inicioPromocao"
            type="date"
            borderColor={
              errors.inicioPromocao?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.inicioPromocao?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <DashboardInput
            {...register("fimPromocao")}
            borderRadius="8px"
            name="fimPromocao"
            type="date"
            borderColor={
              errors.fimPromocao?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.fimPromocao?.message}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <DashboardInput
            {...register("criteriosCondicoes")}
            borderRadius="8px"
            name="criteriosCondicoes"
            placeholder="Critérios e condições"
            type="text"
            borderColor={
              errors.criteriosCondicoes?.message
                ? themeChakra.colors.red[200]
                : "black"
            }
          />

          <Text color={themeChakra.colors.red[200]}>
            {errors.criteriosCondicoes?.message}
          </Text>
        </GridItem>
      </Grid>

      <DashboardButton type="submit" margin="0 auto">
        Enviar
      </DashboardButton>
    </form>
  );
};

/*

 
 */
