import { ClientDTO } from "../dtos/client";

export const data: ClientDTO[] = [
  {
    id: 1,
    cpmf: "05.841.787/0001-38",
    razaoSocial: "BAHIA COMBUSTÍVEL",
    nomeFantasia: "POSTO OCEANO",
    endereco: "AV. ANO MUSSER, 3.200",
    municipioUF: "PORTO SEGURO/BA",
    telefone: "(73) 9864-4800",
    email: "fiscaludi@gmail.com",
    situacao: "ATIVO",
    icons: true,
  },
  {
    id: 2,
    cpmf: "05.841.787/0001-38",
    razaoSocial: "NORDESTE COMBUSTÍVEL",
    nomeFantasia: "POSTO OCEANO",
    endereco: "AV. ANO MUSSER, 3.200",
    municipioUF: "PORTO SEGURO/BA",
    telefone: "(73) 9864-4800",
    email: "fiscaludi@gmail.com",
    situacao: "ATIVO",
    icons: true,
  },
  {
    id: 3,
    cpmf: "05.841.787/0001-38",
    razaoSocial: "NORTEBAHIA COMBUSTÍVEL LTDA",
    nomeFantasia: "POSTO DESEJO",
    endereco: "AV. ANO MUSSER, 3.200",
    municipioUF: "PORTO SEGURO/BA",
    telefone: "(73) 9864-4800",
    email: "fiscaludi@gmail.com",
    situacao: "ATIVO",
    icons: true,
  },
  {
    id: 4,
    cpmf: "05.841.787/0001-38",
    razaoSocial: "POSTOJÁ COMBUSTÍVEL LTDA",
    nomeFantasia: "POSTO VERÃO",
    endereco: "AV. ANO MUSSER, 3.200",
    municipioUF: "PORTO SEGURO/BA",
    telefone: "(73) 9864-4800",
    email: "fiscaludi@gmail.com",
    situacao: "ATIVO",
    icons: true,
  },
];
