import { ReactNode, createContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie-consent";
import { api } from "../service/api";
import { UserDto } from "../dtos/user";
import { SignInCredentials } from "../dtos/auth";

export type AuthContextProviderProps = {
  children: ReactNode;
};

export type AuthContextData = {
  signIn(credentias: SignInCredentials): Promise<void>;
  logout(): void;
  user: UserDto;
  isAuthenticated: boolean;
  isAuthLoading: boolean;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem("logged") === "true"
  );

  useEffect(() => {
    console.log("verificando no contexto");
    const jwtToken = Cookies.get("jwtToken");

    if (!jwtToken) {
      localStorage.removeItem("logged");
      return;
    }
    setIsAuthenticated(true);
    localStorage.setItem("logged", "true");
  }, [isAuthenticated]);

  async function signIn({ email, password }: SignInCredentials): Promise<void> {
    try {
      setIsAuthLoading(true);
      const response = await api.post("/api/login", {
        email: email,
        password: password,
        device_name: "admin-portal-" + email,
      });

      const token = response.data.token;

      Cookies.set("portalx.token", token);
      localStorage.setItem("logged", "true");

      api.defaults.headers["Authorization"] = `Bearer ${token}`;

      setUser(response.data.user);
      setIsAuthenticated(true);
    } catch (error) {
      throw error;
    } finally {
      setIsAuthLoading(false);
    }
  }

  function logout() {
    Cookies.remove("portalx.token");
    setUser({} as UserDto);
  }

  async function getUser() {
    const token = Cookies.get("portalx.token");
    if (token && !user.id) {
      const response = await api.get("/api/users/index");
      setUser(response.data.data[0]);
    }
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        user,
        isAuthLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
