import { useState } from "react";

import { DependentsDTO } from "../../../dtos/dependents";
import { data as dataDependents } from "../../../data/mockDependents";

export function useListDependents() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DependentsDTO[]>([]);

  const getDependents = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const response = await api.get("company/locator/list");

      setData(dataDependents);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getDependents, data, setData };
}
