import { AuthTemplate } from "../components/authTemplate/AuthTemplate";

interface AuthRouteProps {
  children: JSX.Element;
  path?: string;
}

export function AuthRoute({ children, path }: AuthRouteProps) {
  return <AuthTemplate path={path}>{children}</AuthTemplate>;
}
