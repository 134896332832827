import { ReactNode } from "react";
import { Button as ChakraButton, Img, Text } from "@chakra-ui/react";
import { themeChakra } from "../../styles/theme-chakra";
type DashboardButtonProps = {
  icon?: string;
  backgrounDcolor?: string;
  children: ReactNode;
  borderRadius?: string;
  margin?: string;
  type?: "button" | "submit" | "reset";
};

export const DashboardButton = ({
  children,
  icon,
  backgrounDcolor = "#0A60B5",
  type,
  ...rest
}: DashboardButtonProps) => {
  return (
    <ChakraButton
      {...rest}
      color="white"
      backgroundColor={backgrounDcolor}
      maxWidth={226}
      fontSize={16}
      display="flex"
      fontWeight={themeChakra.fontWeights.bold}
      width="100%"
      iconSpacing={0}
      type={`${type || "button"}`}
      _hover={{
        cursor: "pointer",
        opacity: "80%",
        textDecoration: "none",
      }}
      _active={{
        cursor: "pointer",
        opacity: "80%",
      }}
    >
      {icon ? <Img height="35px" src={`/${icon}`} alt="" /> : undefined}
      <Text flexGrow="2"> {children}</Text>
    </ChakraButton>
  );
};
